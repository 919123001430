import './ColumnBoxDroppable.css';

import { DataSourceColumn } from '../../../../../Classes/DataSourceColumn';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { CustomChartDragTypes } from '../../../../../Classes/Charts/CustomChartDragTypes';
import ColumnBox from './ColumnBox';
import GraphSettingsRemoveButton from '../GraphSettingsRemoveButton/GraphSettingsRemoveButton';

type ColumnBoxProps = {
    dataSourceColumn: DataSourceColumn|null;
    setDataSourceColumn: (value:DataSourceColumn) => void;
    nullable: boolean;
    removeColumn: () => void;
    readOnly: boolean;
    numericColumnIds?: Set<number>;
}

const defaultProps = {
    removeColumn: () => {},
    nullable: false,
    readOnly: false
}

export function ColumnBoxDroppable({ dataSourceColumn, setDataSourceColumn, removeColumn, nullable, readOnly, numericColumnIds }: ColumnBoxProps) {
    const [{ canDrop, isOver }, dropRef] = useDrop({
        accept: CustomChartDragTypes.DATA_SOURCE_COLUMN,
        drop: (item: { dataSourceColumn:DataSourceColumn }) => {
            const dsCol = item.dataSourceColumn;

            setDataSourceColumn(dsCol);
            return item;
        },
        collect: (monitor:DropTargetMonitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    const shouldShadeBackground = canDrop && isOver;

    if (readOnly) {
        return (
            <div className='columnBoxDroppable'>
                {dataSourceColumn == null && <div>no type assigned</div>}
                {dataSourceColumn != null && <ColumnBox dataSourceColumn={dataSourceColumn} readOnly={true} numericColumnIds={numericColumnIds}/>}
            </div>
        );
    }

    return (
        <div
            ref={dropRef}
            className={`columnBoxDroppable ${shouldShadeBackground ? 'columnBoxDroppableIsOver' : ''}`}>
            {dataSourceColumn == null && <div>drop type here</div>}
            {dataSourceColumn != null && <ColumnBox dataSourceColumn={dataSourceColumn} numericColumnIds={numericColumnIds}/>}
            {nullable && dataSourceColumn != null && (
                <GraphSettingsRemoveButton onClick={removeColumn}/>
            )}
        </div>
    );
}
ColumnBoxDroppable.defaultProps = defaultProps;
export default ColumnBoxDroppable;