import './GraphSetManager.css';
import { GraphSetFolder } from '../../../../Classes/GraphSetFolder';
import { useEffect } from 'react';
import { Button, ContextMenu } from 'devextreme-react';
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { GraphSetDragTypes } from '../../../../Classes/GraphSetDragTypes';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';
import { moveGraphSetFolder } from '../../../../Reducers/SetManagementReducer';
import useKeycloak from '../../../../Keycloak';

interface GraphSetListItemProps {
    folder:GraphSetFolder;
    setSelectedFolderId:(newId:number) => void;
    folderContextMenuItems: any[];
}

export function GraphSetFolderListItem(props:GraphSetListItemProps) {
    const dispatch = useDispatch();
    const keycloak = useKeycloak();

    const [{ isDragging }, drag, dragPreview] = useDrag({
        type: GraphSetDragTypes.FOLDER,
        collect: (monitor:any) => ({
            isDragging: monitor.isDragging()
        }),
        end: (item:any, monitor:DragSourceMonitor) => {
            const dropRes = monitor.getDropResult() as GraphSetFolder;
            if (dropRes) {
                let destinationFolderId = dropRes.id;
                let folderToMove = item as GraphSetFolder;
                if (destinationFolderId != null && destinationFolderId != folderToMove.id) {
                    dispatch(moveGraphSetFolder({
                        authToken: keycloak.token, 
                        folderId: folderToMove.id, 
                        destinationFolderId: destinationFolderId
                    }));
                }
            }
        },
        item: props.folder
    });

    const [{canDrop, isOver}, drop] = useDrop({
        accept: [GraphSetDragTypes.FOLDER, GraphSetDragTypes.GRAPH_SET],
        drop: () => (props.folder),
        collect: (monitor:DropTargetMonitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    })

    useEffect(() => {
        dragPreview(getEmptyImage());
    }, []);

    const draggingOverClass = canDrop && isOver ? 'canDropInto' : '';
    const draggingOpacity = isDragging ? 0 : 1;

    const folderClass = 'graphSetFolderTemplate';
    const idString = `graphSetFolderID${props.folder.id}`;
    return (
        <div ref={drag} style={{opacity: draggingOpacity}}>
            <div ref={drop}>
                <Button 
                    className={folderClass + ' ' + draggingOverClass}
                    id={idString}
                    icon='folder'
                    text={props.folder.name}
                    onClick={() => {
                        props.setSelectedFolderId(props.folder.id);
                    }}
                    disabled={isDragging}>
                </Button>
                <ContextMenu
                    dataSource={props.folderContextMenuItems}
                    target={`#${idString}`}
                    onItemClick={(e:any) => e.itemData.action()}/>
            </div>
        </div>
    );
}
export default GraphSetFolderListItem;