import { Button } from "devextreme-react"
import { CustomChartFolder } from "../../../../../Classes/Charts/CustomChartFolder"
import { DropTargetMonitor, useDrop } from "react-dnd"
import { CustomChartDragTypes } from "../../../../../Classes/Charts/CustomChartDragTypes"

type FolderNavigationButtionProps = {
    folder: CustomChartFolder
    setCurrentFolder: (newFolder:CustomChartFolder) => void
}

export function FolderNavigationButtion(props: FolderNavigationButtionProps) {
    const [{canDrop, isOver}, drop] = useDrop({
        accept: [CustomChartDragTypes.FOLDER, CustomChartDragTypes.CHART],
        drop: () => (props.folder),
        collect: (monitor:DropTargetMonitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    })
    
    const draggingOverClass = canDrop && isOver ? 'canDropInto' : '';
    return (
        <div ref={drop}>
            <Button 
                className={`breadCrumbButton ${draggingOverClass}`}
                text={props.folder.name}
                onClick={() => {
                    props.setCurrentFolder(props.folder)
                }}/>
        </div>
    )
}
export default FolderNavigationButtion