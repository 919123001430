import './CustomChart.css';
import '../Charts.css';
import { setGraphPreviewImage } from '../../../Reducers/ResultsReducer';
import { Button, Chart, Drawer, TabPanel } from 'devextreme-react';
import React, { useCallback, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomChartSettings } from '../../../Classes/Charts/CustomChartSettings';
import { Item } from 'devextreme-react/tab-panel';
import ChartNotes from './GraphSettings/Notes/ChartNotes';
import { SharedGraphRoleType, canEditGraphSettings } from '../../../Classes/UserGroups/SharedGraphRole';
import ShareSettings from './GraphSettings/SharingSettings/ShareSettings';
import { APIRequestStatus } from '../../../Classes/APIRequestStatus';
import useKeycloak from '../../../Keycloak';
import { RootState } from '../../../Stores/GlobalStore';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import GraphSettings from './GraphSettings/GraphSettings';
import Graph from '../Graph';
import { GraphDataSource } from '../../../Classes/Charts/GraphDataSource';
import { PutPreviewImage, SetDbSeriesVisibility } from '../../../API/CustomChartAPI';
import { getDataSourceTable } from '../../../Utilities/ZoneResultAggregator';
import { CustomSeriesData } from '../../../Classes/Charts/CustomSeriesData';

export enum RightDrawerTab {
    Settings = 0,
    Table = 2,
    Notes = 1,
    Share = 3
}

type CustomChartProps = {
    graphSettings: CustomChartSettings,
    setGraphSettings: (newVal:CustomChartSettings) => void,
    zoneResults:any[]|null,
    isAccessedViaPublicLink: boolean,
    setSharedBy: (newVal:boolean) => void
}

export function CustomChart({ graphSettings, setGraphSettings, isAccessedViaPublicLink, zoneResults, setSharedBy }: CustomChartProps) { 
    const dispatch = useDispatch();
    const { token } = useKeycloak();

    const graphId = graphSettings.chartId;

    const [rightDrawerTab, setRightDrawerTab] = useState<RightDrawerTab>(isAccessedViaPublicLink ? RightDrawerTab.Notes : RightDrawerTab.Settings);
    const [rightDrawerOpen, setRightDrawerOpen] = useState<boolean>(false);

    const drawerAnimationDuration = 200;

    const propertyKeyDict = useSelector((state:RootState) => state.results.plottablePropertyDictionary);

    const [tableDataSource, setTableDataSource] = useState<any[]>([]);
    const [graphDataSource, setGraphDataSource] = useState<GraphDataSource|null>(null);

    const chartRef = useRef<Chart>(null);

    const readOnly = graphSettings.userRole === SharedGraphRoleType.ReadOnly || isAccessedViaPublicLink;

    // Recompute the table and graph results whenever the graph settings change or the results change.
    useEffect(() => {
        if (graphSettings && zoneResults) {
            const cols = graphSettings.dataSourceColumns;
            const cats = graphSettings.dataSourceCategoryIds;
            const filters = graphSettings.stringFiltersByColumnId;
            const newTableDataSource = getDataSourceTable(zoneResults, cols, cats, filters);
            const newGraphDataSource = new GraphDataSource(newTableDataSource, propertyKeyDict, graphSettings);

            setTableDataSource(newTableDataSource);
            setGraphDataSource(newGraphDataSource);
        }
        else {
            setTableDataSource([]);
            setGraphDataSource(null);
        }
    }, [graphSettings, propertyKeyDict, zoneResults]);

    useEffect(() => {
        // Open the settings panel automatically if certain key settings are undefined
        if (graphSettings.graphSetIds.length <= 0 || graphSettings.xAxisColumnId == null || graphSettings.yAxes.length <= 0) {
            setRightDrawerOpen(true);
        }   
    }, [graphSettings.graphSetIds.length, graphSettings.xAxisColumnId, graphSettings.yAxes.length]);

    const chartSettings = graphSettings != null ? (
        <GraphSettings
            graphSettings={graphSettings}
            setGraphSettings={setGraphSettings}
            tableDataSource={tableDataSource}
            graphDataSource={graphDataSource}
            readOnly={readOnly}/>
    ) : (
        <div className='chartSettingsDiv'>
            <LoadingSpinner/>
        </div>
    );

    const exportToFile = (fileType:|'svg'|'png') => {
        const chartObj = chartRef?.current?.instance;
        if (graphSettings && chartObj) {
            chartObj.exportTo(graphSettings.title, fileType);
        }
    }

    const getRightDrawerIcon = (rightDrawerTab:RightDrawerTab) => {
        let rightDrawerIcon = 'preferences'
        switch (rightDrawerTab) {
            case RightDrawerTab.Settings:
                rightDrawerIcon = 'preferences'
                break;
            case RightDrawerTab.Table:
                rightDrawerIcon = 'tableproperties'
                break;
            case RightDrawerTab.Notes:
                rightDrawerIcon = 'textdocument'
                break;
            case RightDrawerTab.Share:
                rightDrawerIcon = 'share'
                break;
        }
        return rightDrawerIcon
    }

    const setPreviewImage = useCallback((blob:Blob) => {
        if (readOnly)
            return null;

        // Make the change in the UI
        dispatch(setGraphPreviewImage({ 
            graphId: graphId, 
            imageBlob: blob
        }));
        // Save the image to the database
        PutPreviewImage(token, graphId, blob);
    }, [dispatch, graphId, readOnly, token]);

    const toggleSeriesVisibility = useCallback((seriesKey:string) => {
        const oldCustomSeriesDataByKey = {...graphSettings.customSeriesDataByKey};
        const newCustomSeriesDataByKey = {...oldCustomSeriesDataByKey};
        let newValue = newCustomSeriesDataByKey[seriesKey]?.visible ?? false;

        const currSeriesData = newCustomSeriesDataByKey[seriesKey];
        if (currSeriesData == null) {
            const newCustSeriesData = new CustomSeriesData();
            newCustSeriesData.visible = newValue;
            newCustomSeriesDataByKey[seriesKey] = newCustSeriesData;
        }
        else {
            newValue = !currSeriesData.visible;
            currSeriesData.visible = newValue;
        }

        // Make the change in the UI
        setGraphSettings({...graphSettings, customSeriesDataByKey: newCustomSeriesDataByKey});
        
        // Make the change in the database (if the graph is not read only)
        if (!readOnly) {
            SetDbSeriesVisibility(token, graphId, seriesKey, newValue).then(response => {
                if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                    // Revert this change if the DB step fails
                    setGraphSettings({...graphSettings, customSeriesDataByKey: oldCustomSeriesDataByKey});
                }
            });
        }
    }, [graphId, graphSettings, readOnly, setGraphSettings, token]);

    return (
        <div className={`customChartWithButtonDiv`}>
            <Drawer
                className='customChartRightSidebarDrawer'
                height={'100%'}
                opened={rightDrawerOpen}
                openedStateMode='shrink'
                position='right'
                revealMode='slide'
                animationDuration={drawerAnimationDuration}
                render={() => {
                    return (
                        <div className='chartRightDrawerDiv'>
                            <Button
                                className='rightSidebarOpenButton'
                                focusStateEnabled={false}
                                icon={getRightDrawerIcon(rightDrawerTab)}
                                onClick={() => {
                                    setRightDrawerOpen(!rightDrawerOpen);
                                }} />
                            <TabPanel
                                className='rightSidePanel'
                                onSelectedItemChange={(selectedItem) => {
                                    if (selectedItem !== null) {
                                        switch (selectedItem.title) {
                                            case 'Settings':
                                                setRightDrawerTab(RightDrawerTab.Settings);
                                                break;
                                            case 'Data Table':
                                                setRightDrawerTab(RightDrawerTab.Table);
                                                break;
                                            case 'Notes':
                                                setRightDrawerTab(RightDrawerTab.Notes);
                                                break;
                                            case 'Share':
                                                setRightDrawerTab(RightDrawerTab.Share);
                                                break;
                                        }
                                    }
                                }}>
                                {!isAccessedViaPublicLink &&
                                <Item
                                    title={'Settings'}
                                    icon='preferences'>
                                    {chartSettings}
                                </Item>}
                                <Item
                                    title={'Notes'}
                                    icon='textdocument'>
                                    {graphSettings &&
                                    <ChartNotes 
                                        chartSettings={graphSettings} 
                                        readOnly={!canEditGraphSettings(graphSettings.userRole)}/>}
                                </Item>
                                {!isAccessedViaPublicLink &&
                                <Item
                                    title={'Share'}
                                    icon='share'>
                                    {graphSettings &&
                                    <ShareSettings
                                        chartSettings={graphSettings}
                                        exportToFile={exportToFile}
                                        setSharedBy={setSharedBy}
                                        setPublicLink={(newPublicId) => {
                                            setGraphSettings({...graphSettings, sharedLinkId: newPublicId});
                                        }}/>}
                                </Item>}
                            </TabPanel>
                        </div>);
                }}>
                <div className='customChartDiv'>
                    {graphSettings != null &&
                    <Graph 
                        ref={chartRef}
                        graphSettings={graphSettings}
                        graphDataSource={graphDataSource}
                        showTitle={true}
                        setPreviewImage={setPreviewImage}
                        toggleSeriesVisibility={toggleSeriesVisibility}/>}
                </div>
            </Drawer>
        </div>
    );
}
export default CustomChart;