import './GraphsPage.css';
import '../../Charts.css';

import { DndProvider } from 'react-dnd';
import CustomGraphDragLayer from './GraphOrganization/CustomGraphDragLayer';
import { Button, Drawer, DropDownButton } from 'devextreme-react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../Stores/GlobalStore';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CustomChartFolder, FindChartInFolder, FindChartParentFolder, FindFolderInFolder, MoveGraphToFolderWithinRoot } from '../../../../Classes/Charts/CustomChartFolder';
import { CreateDbFolder, FolderNameIsDuplicate, GetCustomChartFolders, MoveDbChartsToFolder, RenameDbFolder } from '../../../../API/CustomChartFolderAPI';
import useKeycloak from '../../../../Keycloak';
import { naturalCompare } from '../../../../Utilities/CommonUtilities';
import ChartFolderListItem from './GraphOrganization/ChartFolderListItem';
import CustomChartListItem from './GraphOrganization/CustomChartListItem';
import { GraphInfo } from '../../../../Classes/Charts/GraphInfo';
import CustomChart from '../CustomChart';
import { FetchZoneResultsProps, fetchFilterPropertiesIfNotInCache, fetchPlottableProperties, fetchZoneResultsIfNotInCache, setGraphPreviewImage } from '../../../../Reducers/ResultsReducer';
import { UnitSystemType } from '../../../../Classes/User';
import FolderNavigation from './GraphOrganization/FolderNaviation';
import { setSelectedCustomChartId, setSelectedFolderId } from '../../../../Reducers/CustomChartsReducer';
import { APIRequestStatus } from '../../../../Classes/APIRequestStatus';
import { SetNamePopup } from '../../../SetNamePopup';
import { CreateDbGraph, CustomChartNameIsDuplicate, DeleteCustomChart, DuplicateDbGraph, GetDbGraph, PutPreviewImage, RenameDbGraph, SelectDbGraph } from '../../../../API/CustomChartAPI';
import { CustomChartSettings } from '../../../../Classes/Charts/CustomChartSettings';
import { CreateTemplatePopup } from '../../GraphTemplates/CreateTemplatePopup';
import QuickGraphPopup from '../../../Dashboard/SetManager/GraphSetManager/QuickGraph/QuickGraphPopup';
import { GraphTemplate } from '../../../../Classes/Charts/GraphTemplates';
import { CopyToGraph } from '../../../../API/GraphTemplateAPI';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';

interface GraphsPageProps {
    showSidebar:boolean
}

const sidebarAnimationDuration = 400;

export function GraphsPage({ showSidebar } : GraphsPageProps) {
    const dispatch = useDispatch();
    const { token } = useKeycloak();
    const cachedPreviewImageLookup = useSelector((state:RootState) => state.results.cachedPreviewImageLookup);
    const unitSystem = useSelector((state:RootState) => state.userData.user?.unitSystem) ?? UnitSystemType.English;

    const [rootFolder, setRootFolder] = useState<CustomChartFolder|undefined>(undefined);
    const selectedGraphId = useSelector((state:RootState) => state.customCharts.selectedCustomChartId); 
    const selectedFolderId = useSelector((state:RootState) => state.customCharts.selectedFolderId); 

    const [graphSettings, setGraphSettings] = useState<CustomChartSettings|null>(null);

    const [graphToRename, setGraphToRename] = useState<GraphInfo|null>(null);
    const [folderToRename, setFolderToRename] = useState<CustomChartFolder|null>(null);

    const [showCreateBlankPopup, setShowCreateBlankPopup] = useState<boolean>(false);
    const [showCreateFromTemplatePopup, setShowCreateFromTemplatePopup] = useState<boolean>(false);
    const [showCreateFolderPopup, setShowCreateFolderPopup] = useState<boolean>(false);
    const [graphInfoForTemplate, setGraphInfoForTemplate] = useState<GraphInfo|undefined>(undefined);

    const cachedZoneResults = useSelector((state:RootState) => state.results.cachedZoneResults);
    const selectedZoneResults = selectedGraphId ? cachedZoneResults[selectedGraphId] : null;

    const currentFolder = useMemo(() => {
        return FindFolderInFolder(rootFolder, selectedFolderId) ?? rootFolder;
    }, [rootFolder, selectedFolderId]);

    // Fetch graph settings when the graph id changes
    useEffect(() => {
        const fetchData = async () => {
            if (selectedGraphId == null) {
                setGraphSettings(null);
                return;
            }

            // Fetch the graph
            const graphResponse = await GetDbGraph(token, selectedGraphId);

            // Set graph settings in the UI
            if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(graphResponse) && graphResponse.data) {
                const newGraphSettingsObject = graphResponse.data;
                setGraphSettings(newGraphSettingsObject);
            }
            else {
                setGraphSettings(null);
            }
        }
        fetchData();
    }, [selectedGraphId, unitSystem]);

    const loadingZoneResults = useRef<boolean>(false);
    const loadingFilteredProperties = useRef<boolean>(false);
    useEffect(() => {
        const fetchResultsIfNeeded = (graphId:number) => {
            if (graphId != null && !loadingZoneResults.current && token != null) {
                loadingZoneResults.current = true;
                dispatch(fetchZoneResultsIfNotInCache({authToken: token, graphId } as FetchZoneResultsProps))
                .then(() => {
                    loadingZoneResults.current = false;
                });
            }
        }

        const fetchFilterPropertiesIfNeeded = (graphId:number) => {
            if (graphId != null && !loadingFilteredProperties.current) {
                loadingFilteredProperties.current = true;
                dispatch(fetchFilterPropertiesIfNotInCache({authToken: token, graphId} as FetchZoneResultsProps))
                .then(() => {
                    loadingFilteredProperties.current = false;
                });
            }
        }

        if (graphSettings != null) {
            fetchResultsIfNeeded(graphSettings.chartId);
            fetchFilterPropertiesIfNeeded(graphSettings.chartId);
        }
    }, [dispatch, graphSettings])

    useEffect(() => {
        GetCustomChartFolders(token).then(newRootFolder => {
            setRootFolder(newRootFolder);
        });
    }, []);

    useEffect(() => {
        dispatch(fetchPlottableProperties(unitSystem));
    }, [dispatch, unitSystem]);
    
    const addButtonActions = useMemo(() => [
        { 
            name: 'New Blank Graph',
            action: () => {
                setShowCreateBlankPopup(true);
            }
        },
        { 
            name: 'New From Template',
            action: () => {
                setShowCreateFromTemplatePopup(true);
            }
        }
    ], []);

    const chartListUI = useMemo(() => {
        if (currentFolder == null || rootFolder == null) {
            return <div></div>;
        }

        const sortedSubFolders = [...currentFolder.subFolders].sort((a, b) => naturalCompare(a.name, b.name));
        const sortedCharts = [...currentFolder.graphInfos].sort((a, b) => naturalCompare(a.title, b.title));
        return (
            <div className={'savedGraphsList'}>
                {sortedSubFolders.map((folder:CustomChartFolder, index:number) => 
                    <ChartFolderListItem 
                        customChartFolder={folder} 
                        rootFolder={rootFolder}
                        setRootFolder={setRootFolder}
                        onRenameClicked={() => {
                            setFolderToRename(folder);
                        }}
                        key={`folder${folder.id}`}/>)}
                {sortedCharts.map((graphInfo:GraphInfo, index:number) =>
                    <CustomChartListItem 
                        graphInfo={graphInfo}
                        rootFolder={rootFolder}
                        setRootFolder={setRootFolder}
                        onRenameClicked={() => {
                            setGraphToRename(graphInfo);
                        }}
                        onDuplicateClicked={() => {
                            // Make the change in the DB first. We'll need the name and id of the new graph from the backend before we can make the change
                            // in the UI.
                            DuplicateDbGraph(token, graphInfo.id).then(response => {
                                if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response) && response.data != null) {
                                    const newGraphInfo = response.data;
                                    
                                    // Make the change in the UI
                                    const newRootFolder = {...rootFolder};
                                    const selectedFolder = FindFolderInFolder(newRootFolder, selectedFolderId) ?? newRootFolder;
                                    selectedFolder.graphInfos = [...selectedFolder.graphInfos, newGraphInfo];
                                    setRootFolder(newRootFolder);

                                    // Copy the preview image to the new graph in the db
                                    if (graphInfo.id in cachedPreviewImageLookup) {
                                        const oldPreviewImageBlob = cachedPreviewImageLookup[graphInfo.id].imageBlob;
                                        if (oldPreviewImageBlob != null) {
                                            // Make the change in the UI
                                            dispatch(setGraphPreviewImage({
                                                graphId: newGraphInfo.id,
                                                imageBlob: oldPreviewImageBlob
                                            }));

                                            // Make the change in the DB
                                            PutPreviewImage(token, newGraphInfo.id, oldPreviewImageBlob);
                                        }
                                    }
                                }
                            });
                        }}
                        onSaveAsTemplateClicked={() => {
                            setGraphInfoForTemplate(graphInfo);
                        }}
                        onDeleteClicked={() => {
                            let result = confirm(`Are you sure you want to delete ${graphInfo.title}?`, "Confirm Delete");  
                            result.then((confirmDelete) => {
                                if (confirmDelete) {
                                    // Make the change in the DB
                                    DeleteCustomChart(token, graphInfo.id).then(success => {
                                        if (success) {
                                            notify(graphInfo.title + ' has been deleted.', 'success');

                                            // Make the change in the UI
                                            if (graphInfo.id === selectedGraphId) {
                                                dispatch(setSelectedCustomChartId(null));
                                            }

                                            const newRootFolder = {...rootFolder};
                                            const graphParentFolder = FindChartParentFolder(newRootFolder, graphInfo.id) ?? newRootFolder;
                                            if (graphParentFolder) {
                                                graphParentFolder.graphInfos = graphParentFolder.graphInfos.filter(i => i.id !== graphInfo.id);
                                                setRootFolder({...newRootFolder});
                                            }
                                        }
                                        else {
                                            notify(graphInfo.title + ' could not be deleted.', 'error');
                                        }
                                    });
                                }
                            });
                        }}
                        key={`chart${graphInfo.id}`}/>)}
            </div>
        )
    }, [currentFolder, rootFolder, selectedGraphId]);

    return (
        <div className="graphsPage">
            <DndProvider backend={TouchBackend} options={{enableMouseEvents: true}} context={window}>
                <CustomGraphDragLayer cachedPreviewImageLookup={cachedPreviewImageLookup}/>
                <Drawer
                    className='customChartSidebarDrawer'
                    opened={showSidebar ?? true}
                    animationDuration={sidebarAnimationDuration}
                    render={() => {
                        return (
                            <div className='mainSidebarDiv dx-theme-accent-as-background-color'>
                                <div className='mainSidebarHeaderDiv'>
                                        <h4 className='sidebarCategoryTitle'>
                                            Graphs
                                            <DropDownButton
                                                className='splitAddButton'
                                                displayExpr="name"
                                                icon="add" 
                                                hint='Create graph'
                                                dropDownOptions={{
                                                    width: '11em'
                                                }}
                                                onItemClick={(e:any) => {
                                                    let itemAction = e.itemData.action;
                                                    itemAction();
                                                }}
                                                items={addButtonActions}/>
                                            <Button
                                                icon="newfolder" 
                                                type='success'
                                                disabled={currentFolder == null}
                                                onClick={() => setShowCreateFolderPopup(true)}
                                                hint='Create graph folder'/>
                                        </h4>
                                        {/* No need to show folder navigation control if selected folder is root */}
                                        {currentFolder != null && rootFolder?.id !== currentFolder.id &&
                                        <FolderNavigation 
                                            rootFolder={rootFolder}
                                            currentFolder={currentFolder}
                                            setCurrentFolder={(newFolder:CustomChartFolder) => dispatch(setSelectedFolderId(newFolder.id))}/>}
                                    </div>
                                    {chartListUI}
                            </div>
                        )}}
                    openedStateMode='shrink'
                    position='left'
                    revealMode='slide'>
                    <div style={{height: '100%'}}>
                        {!selectedGraphId &&
                            <div className='noGraphsMessage'>
                                <h3>Create a new graph or select an existing one to get started.</h3>
                                <h3>Click the + button in the sidebar on the left to create a new graph.</h3>
                            </div>
                        }
                        {graphSettings && selectedGraphId &&
                        <CustomChart 
                            graphSettings={graphSettings} 
                            setGraphSettings={setGraphSettings} 
                            isAccessedViaPublicLink={false}
                            setSharedBy={(newVal) => {
                                if (rootFolder) {
                                    const newRootFolder = {...rootFolder};
                                    const currGraphInfo = FindChartInFolder(newRootFolder, selectedGraphId);
                                    if (currGraphInfo) {
                                        currGraphInfo.isSharedByMe = newVal;
                                        setRootFolder(newRootFolder);
                                    }
                                }
                            }}
                            zoneResults={selectedZoneResults}/>}
                    </div>
                </Drawer>
            </DndProvider>
            <SetNamePopup
                title='New Graph Name'
                validateForDuplicate={(name:string) => CustomChartNameIsDuplicate(token, name, currentFolder?.id ?? null)}
                applyButtonName='Create'
                oldName={''}
                showPopup={showCreateBlankPopup}
                hidePopup={() => setShowCreateBlankPopup(false)}
                applySetName={(name:string) => {
                    if (rootFolder != null) {
                        // Make the change in the db
                        CreateDbGraph(token, name, selectedFolderId).then(response => {
                            if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response) && response.data != null) {
                                // Make the change in the UI
                                const newGraphInfo = response.data;
                                const newRootFolder = {...rootFolder};
                                const currFolder = FindFolderInFolder(newRootFolder, selectedFolderId) ?? newRootFolder;
                                currFolder.graphInfos.push(newGraphInfo);
                                setRootFolder(newRootFolder);

                                // Automatically select the new graph
                                dispatch(setSelectedCustomChartId(newGraphInfo.id));
                                SelectDbGraph(token, newGraphInfo.id);

                                // Start loading results for the new graph (these results will be empty)
                                dispatch(fetchZoneResultsIfNotInCache({authToken: token, graphId: newGraphInfo.id} as FetchZoneResultsProps));
                            }
                        });
                    }
                }}/>
            <SetNamePopup
                title='Rename Graph'
                validateForDuplicate={(name:string) => CustomChartNameIsDuplicate(token, name, currentFolder?.id ?? null)}
                applyButtonName='Apply'
                oldName={graphToRename?.title ?? ''}
                showPopup={graphToRename != null}
                hidePopup={() => setGraphToRename(null)}
                applySetName={(newName:string) => {
                    if (rootFolder && graphToRename) {
                        const oldName = graphToRename.title ?? '';
                        // Make the change in the UI
                        const newRootFolder = {...rootFolder};
                        const graph = FindChartInFolder(newRootFolder, graphToRename.id);
                        if (graph) {
                            graph.title = newName;
                        }
                        setRootFolder(newRootFolder);

                        if (graphSettings != null && graphToRename.id === graphSettings.chartId) {
                            const newGraphSettings = {...graphSettings};
                            newGraphSettings.title = newName;
                            setGraphSettings(newGraphSettings);
                        }

                        // Make the change in the DB
                        RenameDbGraph(token, graphToRename.id, newName)
                        .then(response => {
                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Revert the change if something goes wrong
                                const revertedRootFolder = {...rootFolder};
                                if (graph) {
                                    graph.title = oldName;
                                }
                                setRootFolder(revertedRootFolder);
                            }
                        });
                    }
                }}/>
            <SetNamePopup
                title='New Folder Name'
                validateForDuplicate={(name:string) => FolderNameIsDuplicate(token, name, currentFolder?.id)}
                applyButtonName='Create'
                oldName={''}
                showPopup={showCreateFolderPopup}
                hidePopup={() => setShowCreateFolderPopup(false)}
                applySetName={(name:string) => {
                    if (rootFolder != null) {
                        // Make the change in the db
                        CreateDbFolder(token, name, selectedFolderId).then(response => {
                            if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response) && response.data != null) {
                                // Make the change in the UI
                                const newFolder = response.data;
                                const newRootFolder = {...rootFolder};
                                const currFolder = FindFolderInFolder(newRootFolder, selectedFolderId) ?? newRootFolder;
                                currFolder.subFolders.push(newFolder);
                                setRootFolder(newRootFolder);
                            }
                        });
                    }
                }}/>
            <SetNamePopup
                title='Rename Folder'
                validateForDuplicate={(name:string) => FolderNameIsDuplicate(token, name, currentFolder?.id)}
                applyButtonName='Apply'
                oldName={folderToRename?.name ?? ''}
                showPopup={folderToRename != null}
                hidePopup={() => setFolderToRename(null)}
                applySetName={(newName:string) => {
                    if (folderToRename?.id != null && rootFolder) {
                        // Make the change in the db
                        RenameDbFolder(token, newName, folderToRename.id).then(response => {
                            if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Make the change in the UI
                                const newRootFolder = {...rootFolder};
                                const folder = FindFolderInFolder(newRootFolder, folderToRename.id);
                                if (folder) {
                                    folder.name = newName;
                                }
                                setRootFolder(newRootFolder);
                            }
                        });
                    }
                }}/>
            <CreateTemplatePopup 
                graphInfo={graphInfoForTemplate}
                hidePopup={() => setGraphInfoForTemplate(undefined)}/>
            <QuickGraphPopup
                show={showCreateFromTemplatePopup}
                graphSet={null}
                hidePopup={() => setShowCreateFromTemplatePopup(false)}
                createFromTemplate={(template:GraphTemplate|undefined, graphSetIds:number[], previewImageBlob:Blob|null) => {
                    if (template) {
                        const currSelectedFolderId = selectedFolderId;
                        CopyToGraph(token, template.chartId, currSelectedFolderId, template.title, graphSetIds).then(response => {
                            if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                const newGraphInfo = response.data;
                                if (newGraphInfo != null) {
                                    // Save the preview image to the db
                                    if (previewImageBlob != null) {
                                        PutPreviewImage(token, newGraphInfo.id, previewImageBlob);
                                    }

                                    // Add graph info to UI
                                    if (rootFolder != null) {
                                        const newRootFolder = {...rootFolder};
                                        const selectedFolder = FindFolderInFolder(newRootFolder, currSelectedFolderId) ?? newRootFolder;
                                        if (selectedFolder != null) {
                                            selectedFolder.graphInfos = [...selectedFolder.graphInfos, newGraphInfo];
                                        }
                                        setRootFolder(newRootFolder);
                                    }
                                }

                                APIRequestStatus.showToast(response);
                            }
                        });

                        setShowCreateFromTemplatePopup(false);
                    }
                }}/>
        </div>
    );
}