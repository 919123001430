import { Button } from 'devextreme-react';
import { DragLayerMonitor, useDragLayer } from 'react-dnd'
import { GraphSet } from '../../../../Classes/GraphSet';
import { GraphSetDragTypes } from '../../../../Classes/GraphSetDragTypes';
import { GraphSetFolder } from '../../../../Classes/GraphSetFolder';

const GraphSetDragLayer: React.FC = () => {  
    
    const {isDragging, currentOffset, item, type} = useDragLayer(
        (monitor: DragLayerMonitor) => {
            return {
                isDragging: monitor.isDragging(),
                currentOffset: monitor.getSourceClientOffset(),
                item: monitor.getItem() as any,
                type: monitor.getItemType()
            };
        }
    )

    // Return null if no drag is occurring
    if (!isDragging || !currentOffset)
        return null

    let draggingStyle = {
        // functional
        transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
        position: 'fixed',
        top: 0,
        left: 0,
        pointerEvents: 'none', 

        // design only
        margin: '0em',
        opacity: 0.75
    } as React.CSSProperties

    // Render graph set drag
    if (type === GraphSetDragTypes.GRAPH_SET) {
        let graphSet = item as GraphSet
        let elementIdString = `graphSetID${graphSet.graphSetId}`
        let originalItemWidth = document.getElementById(elementIdString)?.clientWidth

        let sharedMessage = '';
        if (graphSet.isSharedByMe && graphSet.isSharedWithMe) {
            sharedMessage = 'shared';
        }
        else if (graphSet.isSharedByMe) {
            sharedMessage = 'shared by me';
        }
        else if (graphSet.isSharedWithMe) {
            sharedMessage = 'shared with me';
        }

        return (
            <div style={draggingStyle}>
                <div 
                    className='graphSetListItem'
                    style={{width: originalItemWidth, backgroundColor: 'white'}}>
                    <div className='graphSetListItemTitle'>
                        <b>{ graphSet.name }</b>
                        {sharedMessage &&
                        <div className='sharedIndicator'>
                            <span>{sharedMessage}</span>
                            <i className='dx-icon-share'/>
                        </div>}
                    </div>
                    <div className='graphSetListItemDropIndicator'>
                        {graphSet.numberOfUploadSets.toLocaleString()} upload sets
                    </div>
                </div>
            </div>
        )
    }
    // Render folder drag
    else {
        let folder = item as GraphSetFolder
        const folderClass = 'graphSetFolderTemplate'
        let elementIdString = `graphSetFolderID${folder.id}`
        let originalItemWidth = document.getElementById(elementIdString)?.clientWidth
        return (
            <div style={draggingStyle}>
                <Button 
                    className={folderClass}
                    style={{width: originalItemWidth}}
                    icon='folder'
                    text={folder.name}>
                </Button>
            </div>
        )
    }
};

export default GraphSetDragLayer;
  