import { ContextMenu } from 'devextreme-react';
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { CustomChartDragTypes } from '../../../../../Classes/Charts/CustomChartDragTypes';
import { CustomChartFolder, FindChartParentFolder, MoveGraphToFolderWithinRoot } from '../../../../../Classes/Charts/CustomChartFolder';
import { setSelectedCustomChartId } from '../../../../../Reducers/CustomChartsReducer';
import { RootState } from '../../../../../Stores/GlobalStore';
import { FetchPreviewImageProps, fetchPreviewImage } from '../../../../../Reducers/ResultsReducer';
import useKeycloak from '../../../../../Keycloak';
import { GraphInfo } from '../../../../../Classes/Charts/GraphInfo';
import { MoveDbChartsToFolder } from '../../../../../API/CustomChartFolderAPI';
import { APIRequestStatus } from '../../../../../Classes/APIRequestStatus';
import { SelectDbGraph } from '../../../../../API/CustomChartAPI';
import { SharedGraphRoleType } from '../../../../../Classes/UserGroups/SharedGraphRole';

interface CustomChartListItemProps {
    graphInfo: GraphInfo,
    rootFolder: CustomChartFolder,
    setRootFolder: (newVal:CustomChartFolder) => void,
    onRenameClicked: () => void;
    onDuplicateClicked: () => void;
    onSaveAsTemplateClicked: () => void;
    onDeleteClicked: () => void;
}

function CustomChartListItem({ graphInfo, rootFolder, setRootFolder, onRenameClicked, onDuplicateClicked, 
    onSaveAsTemplateClicked, onDeleteClicked } :CustomChartListItemProps) {

    const dispatch = useDispatch();
    const { token } = useKeycloak();

    const graphId = graphInfo.id;

    const selectedCustomChartId = useSelector((state:RootState) => state.customCharts.selectedCustomChartId); 
    const cachedPreviewImageLookup = useSelector((state:RootState) => state.results.cachedPreviewImageLookup);
    const [extraButtonStyle, setExtraButtonStyle] = useState<CSSProperties>({} as CSSProperties);
    const loadingPreviewImage = useRef<boolean>(false);

    const isSelected = selectedCustomChartId === graphId;

    const move = useCallback((destinationFolderId:number|undefined) => {
        const parentFolderOfGraphToMove = FindChartParentFolder(rootFolder, graphInfo.id);
        const newRootFolder = MoveGraphToFolderWithinRoot(rootFolder, graphInfo.id, destinationFolderId);
        if (newRootFolder != null && parentFolderOfGraphToMove != null) {
            // Make the change in the UI
            setRootFolder(newRootFolder);

            // Make the change in the DB
            MoveDbChartsToFolder(token, destinationFolderId, [graphInfo.id])
            .then(response => {
                if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                    // Revert the UI change if the db save fails
                    const revertedRootFolder = MoveGraphToFolderWithinRoot(newRootFolder, graphInfo.id, parentFolderOfGraphToMove.id);
                    if (revertedRootFolder) {
                        setRootFolder(revertedRootFolder);
                    }
                }
            });
        }
    }, [graphInfo.id, rootFolder, setRootFolder, token]);

    const contextMenuItems = useMemo(() => {
        return [
            {
                text: 'Rename',
                disabled: graphInfo.userRole < SharedGraphRoleType.Admin,
                action: () => {
                    onRenameClicked();
                }
            },
            {
                text: 'Duplicate',
                disabled: graphInfo.userRole < SharedGraphRoleType.Collaborator,
                action: () => {
                    onDuplicateClicked();
                }
            },
            {
                text: 'Save as Template',
                action: () => {
                    onSaveAsTemplateClicked();
                }
            },
            {
                text: 'Delete',
                disabled: graphInfo.userRole < SharedGraphRoleType.Admin,
                action: () => {
                    onDeleteClicked();
                }
            }
        ];
    }, [graphInfo.userRole, onRenameClicked, onDuplicateClicked, onSaveAsTemplateClicked, onDeleteClicked]);
    
    const [{ isDragging }, drag, dragPreview] = useDrag({
        type: CustomChartDragTypes.CHART,
        collect: (monitor:any) => ({
            isDragging: monitor.isDragging()
        }),
        end: (item:any, monitor:DragSourceMonitor) => {
            const dropRes = monitor.getDropResult();
            if (dropRes && Object.keys(dropRes as Object).length > 0) {
                let destinationFolderId = (dropRes as CustomChartFolder).id || undefined;
                move(destinationFolderId);
            }
        },
        item: graphInfo
    });
    
    // You cannot drop graphs unto other graphs. This is just here so we can detect when a graph is being hovered during a drag
    const [{canDrop, isOver}, drop] = useDrop({
        accept: [CustomChartDragTypes.FOLDER, CustomChartDragTypes.CHART],
        drop: () => (undefined),
        collect: (monitor:DropTargetMonitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    })

    const opacity = isDragging ? 0.0 : 1;

    useEffect(() => {
        dragPreview(getEmptyImage())
    }, []);

    const renderPreviewImage = useMemo(() => {
        if (!(graphId in cachedPreviewImageLookup)) {
            // Kick off a function to fetch this image from the server.
            if (!loadingPreviewImage.current) {
                loadingPreviewImage.current = true;
                dispatch(fetchPreviewImage({
                    authToken: token,
                    graphId: graphId
                } as FetchPreviewImageProps))
                .then(() => {
                    loadingPreviewImage.current = false;
                });
            }
            return <div/>;
        }

        const imageURL = cachedPreviewImageLookup[graphId].imageURL;
        return (
            <img 
                src={imageURL} 
                width={'100%'}
                height={'100%'}
                loading={'lazy'}
                draggable={false}
                alt=''/>
        );
    }, [cachedPreviewImageLookup, dispatch, graphId, token])

    let sharedMessage = ''
    if (graphInfo.isSharedWithMe && graphInfo.isSharedByMe) {
        sharedMessage = 'shared'
    }
    else if (graphInfo.isSharedWithMe) {
        sharedMessage = 'shared with me'
    }
    else if (graphInfo.isSharedByMe) {
        sharedMessage = 'shared by me'
    }

    let chartClass = isSelected ? 'savedGraphTemplate selectedSavedGraph' : 'savedGraphTemplate'
    let idString = `customChartID${graphId}`
    return (
        <div ref={drag} style={{opacity}}>
            <div ref={drop}>
                <button
                    className={chartClass}
                    id={idString}
                    style={extraButtonStyle}
                    onMouseEnter={() => {
                        let newStyle:CSSProperties = {
                            background: 'rgb(245,245,245)'
                        }
                        if (canDrop)
                            newStyle.background = 'white'
                        else if (!isDragging) {
                            newStyle.cursor = 'pointer'
                        }

                        if (extraButtonStyle.background !== newStyle.background || extraButtonStyle.cursor !== newStyle.cursor) {
                            setExtraButtonStyle(newStyle)
                        }
                    }}
                    onMouseLeave={() => {
                        if (extraButtonStyle.background !== 'white') {
                            setExtraButtonStyle({
                                background: 'white'
                            } as CSSProperties)
                        }
                    }}
                    onClick={() => {
                        if (!isSelected && !isDragging) {
                            dispatch(setSelectedCustomChartId(graphId));
                            SelectDbGraph(token, graphId);
                        }
                    }}>
                    <div className='savedGraphTemplateContent'>
                        <h5>{graphInfo.title}</h5>
                        <div style={{width:'100%', height:'7rem'}}>
                            {renderPreviewImage}
                        </div>
                        {sharedMessage &&
                        <div className='sharedIndicator'>
                            <span>{sharedMessage}</span>
                            <i className='dx-icon-share'/>
                        </div>}
                    </div>
                </button>
                <ContextMenu
                    dataSource={contextMenuItems}
                    target={`#${idString}`}
                    onItemClick={(e:any) => {
                        e.itemData.action()
                    }}/>
            </div>
        </div>
    );
}

export default CustomChartListItem;

