import { Button } from "devextreme-react"
import { DropTargetMonitor, useDrop } from "react-dnd"
import { GraphSetDragTypes } from "../../../../Classes/GraphSetDragTypes"
import { GraphSetFolder } from "../../../../Classes/GraphSetFolder"

type FolderNavigationButtonProps = {
    folder: GraphSetFolder
    setCurrentFolder: (newFolder:GraphSetFolder) => void
}

export function FolderNavigationButton(props: FolderNavigationButtonProps) {

    const [{canDrop, isOver}, drop] = useDrop({
        accept: [GraphSetDragTypes.FOLDER, GraphSetDragTypes.GRAPH_SET],
        drop: () => (props.folder),
        collect: (monitor:DropTargetMonitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    const draggingOverClass = canDrop && isOver ? 'canDropInto' : '';
    return (
        <div ref={drop}> 
            <Button 
                className={`breadCrumbButton ${draggingOverClass}`}
                text={props.folder.name}
                onClick={() => {
                    props.setCurrentFolder(props.folder)
                }}/>
        </div>
    )
}
export default FolderNavigationButton