import './GraphSetManager.css';
import { GraphSet } from '../../../../Classes/GraphSet';
import { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { moveGraphSet, selectGraphSet } from '../../../../Reducers/SetManagementReducer';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { GraphSetDragTypes } from '../../../../Classes/GraphSetDragTypes';
import { GraphSetFolder } from '../../../../Classes/GraphSetFolder';
import { useEffect } from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { ContextMenu } from 'devextreme-react';
import useKeycloak from '../../../../Keycloak';
import StrictModeDroppable from '../StrictModeDroppable';

interface GraphSetListItemProps {
    graphSet:GraphSet;
    isSelected:boolean;
    contextMenuItems:any;
}

export function GraphSetListItem(props:GraphSetListItemProps) {
    const dispatch = useDispatch();
    const keycloak = useKeycloak();
    const numberOfUploadSets = props.graphSet.numberOfUploadSets;

    const [{ isDragging }, drag, dragPreview] = useDrag({
        type: GraphSetDragTypes.GRAPH_SET,
        collect: (monitor:any) => ({
            isDragging: monitor.isDragging()
        }),
        end: (item:any, monitor:DragSourceMonitor) => {
            const dropRes = monitor.getDropResult() as GraphSetFolder;
            if (dropRes) {
                let destinationFolderId = dropRes.id;
                let graphSetToMove = item as GraphSet;
                if (destinationFolderId != null && graphSetToMove != null) {
                    dispatch(moveGraphSet({
                        authToken: keycloak.token, 
                        graphSetId: graphSetToMove.graphSetId, 
                        destinationFolderId: destinationFolderId
                    }));
                }
            }
        },
        item: props.graphSet
    });

    useEffect(() => {
        dragPreview(getEmptyImage());
    }, []);

    const getGraphSetListItemStyle = () => {
        const opacity = isDragging ? 0 : 1;
        let style = {
            backgroundColor: props.isSelected ? '#D0E9FF' : '#fdfdfd',
            borderColor: props.isSelected ? 'black' : "#ddd",
            opacity: opacity
        }
        return style
    }

    const getGraphSetListItemDropIndicatorStyle = (isDraggingOver:boolean) => {
        if (isDraggingOver) {
            return {
                backgroundColor: '#f5f5f5'
            };
        }
        return {};
    }

    let sharedMessage = '';
    if (props.graphSet.isSharedByMe && props.graphSet.isSharedWithMe) {
        sharedMessage = 'shared';
    }
    else if (props.graphSet.isSharedByMe) {
        sharedMessage = 'shared by me';
    }
    else if (props.graphSet.isSharedWithMe) {
        sharedMessage = 'shared with me';
    }

    const idString = `graphSetID${props.graphSet.graphSetId}`;
    return (
        <div 
            ref={drag}
            id={idString}
            className='graphSetListItem'
            style={getGraphSetListItemStyle()}
            onClick={() => dispatch(selectGraphSet(props.graphSet.graphSetId))}>
            <div className='graphSetListItemTitle'>
                <b>{ props.graphSet.name }</b>
                {sharedMessage &&
                <div className='sharedIndicator'>
                    <span>{sharedMessage}</span>
                    <i className='dx-icon-share'/>
                </div>}
            </div>
            <StrictModeDroppable 
                droppableId={`graphSetDroppable${props.graphSet.graphSetId}`}
                mode="standard"
                renderClone={() => <div/>}>
                    {(droppableProvided: DroppableProvided, snapshot:DroppableStateSnapshot) => (
                    <div 
                        className='graphSetListItemDropIndicator'
                        style={getGraphSetListItemDropIndicatorStyle(snapshot.isDraggingOver)}
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}>
                            {numberOfUploadSets.toLocaleString()} upload sets
                            {droppableProvided.placeholder}
                    </div>
                )}
            </StrictModeDroppable>
             <ContextMenu
                dataSource={props.contextMenuItems}
                target={`#${idString}`}
                onItemClick={(e:any) => e.itemData.action()}/> 
        </div>
    );
}
export default GraphSetListItem;