import { Button, ContextMenu } from 'devextreme-react';
import { useCallback, useEffect, useMemo } from 'react';
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { CustomChartDragTypes } from '../../../../../Classes/Charts/CustomChartDragTypes';
import { CountChartsInFolder, CountFoldersInFolder, CustomChartFolder, FindChartInFolder, FindFolderParentFolder, MoveFolderToFolderWithinRoot } from '../../../../../Classes/Charts/CustomChartFolder';
import { setSelectedCustomChartId, setSelectedFolderId } from '../../../../../Reducers/CustomChartsReducer';
import { DeleteDbFolder, MoveDbFoldersToFolder } from '../../../../../API/CustomChartFolderAPI';
import useKeycloak from '../../../../../Keycloak';
import { APIRequestStatus } from '../../../../../Classes/APIRequestStatus';
import { confirm } from 'devextreme/ui/dialog';  
import notify from 'devextreme/ui/notify';
import { RootState } from '../../../../../Stores/GlobalStore';

interface ChartFolderListItemProps {
    customChartFolder: CustomChartFolder,
    rootFolder: CustomChartFolder,
    setRootFolder: (newVal:CustomChartFolder) => void,
    onRenameClicked: () => void
}
function ChartFolderListItem({ customChartFolder, rootFolder, setRootFolder, onRenameClicked } :ChartFolderListItemProps) {
    const dispatch = useDispatch();
    const { token } = useKeycloak();
    
    const selectedGraphId = useSelector((state:RootState) => state.customCharts.selectedCustomChartId); 

    const folderId = customChartFolder.id;

    const move = useCallback((destinationFolderId:number|null|undefined) => {
        if (folderId != null && folderId !== destinationFolderId) {
            const parentFolderOfFolderToMove = FindFolderParentFolder(rootFolder, folderId);
            const newRootFolder = MoveFolderToFolderWithinRoot(rootFolder, folderId, destinationFolderId);
            if (newRootFolder != null && parentFolderOfFolderToMove != null) {
                // Make the change in the UI
                setRootFolder(newRootFolder);
    
                // Make the change in the DB
                MoveDbFoldersToFolder(token, destinationFolderId ?? null, [folderId])
                .then(response => {
                    if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                        // Revert the UI change if the db save fails
                        const revertedRootFolder = MoveFolderToFolderWithinRoot(newRootFolder, folderId, parentFolderOfFolderToMove.id);
                        if (revertedRootFolder) {
                            setRootFolder(revertedRootFolder);
                        }
                    }
                });
            }
        }
    }, [folderId, rootFolder, setRootFolder, token]);

    const [{ isDragging }, drag, dragPreview] = useDrag({
        type: CustomChartDragTypes.FOLDER,
        collect: (monitor:any) => ({
            isDragging: monitor.isDragging()
        }),
        end: (item:any, monitor:DragSourceMonitor) => {
            const dropRes = monitor.getDropResult() as CustomChartFolder;
            if (dropRes) {
                let destinationFolderId = dropRes.id || undefined;
                move(destinationFolderId);
            }
        },
        item: customChartFolder
    });

    const [{canDrop, isOver}, drop] = useDrop({
        accept: [CustomChartDragTypes.FOLDER, CustomChartDragTypes.CHART],
        drop: () => (customChartFolder),
        collect: (monitor:DropTargetMonitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    })

    const draggingOverClass = canDrop && isOver ? 'canDropInto' : '';
    const draggingOpacity = isDragging ? 0 : 1;

    useEffect(() => {
        dragPreview(getEmptyImage())
    }, [dragPreview]);

    const contextMenuItems = useMemo(() => [
        {
            text: 'Rename',
            action: () => {
                onRenameClicked();
            }
        },
        {
            text: 'Delete',
            action: () => {
                const numFoldersInFolder = CountFoldersInFolder(customChartFolder); 
                const numChartsInFolder = CountChartsInFolder(customChartFolder);
                let result = confirm(`Are you sure you want to delete ${customChartFolder.name}? All graphs (${numChartsInFolder}) and folders (${numFoldersInFolder}) 
                    within this folder will be deleted.`, "Confirm Delete");  
                result.then((confirmDelete) => {
                    if (confirmDelete && token && customChartFolder.id) { 
                        // Make the change in the DB
                        DeleteDbFolder(token, customChartFolder.id).then(response => {
                            if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                // Let the user know the folder was deleted.
                                notify(customChartFolder.name + ' has been deleted.', 'success');

                                // Make the change in the UI
                                const newRootFolder = {...rootFolder};

                                // Clear the selected graph id if its parent folder was deleted.
                                const doomedGraphInfo = FindChartInFolder(newRootFolder, selectedGraphId);
                                if (doomedGraphInfo != null) {
                                    dispatch(setSelectedCustomChartId(null));
                                }

                                const folderParentFolder = FindFolderParentFolder(newRootFolder, customChartFolder.id) ?? newRootFolder;
                                if (folderParentFolder) {
                                    folderParentFolder.subFolders = folderParentFolder.subFolders.filter(i => i.id !== customChartFolder.id);
                                    setRootFolder({...newRootFolder});
                                }
                            }
                        });
                    }
                });
            }
        }
    ], [customChartFolder, dispatch, onRenameClicked, rootFolder, selectedGraphId, setRootFolder, token]);

    const folderClass = 'graphFolderTemplate'
    const idString = `customChartFolderID${customChartFolder.id}`
    return (
        <div ref={drag} style={{opacity: draggingOpacity}}>
            <div ref={drop}>
                <Button 
                    className={folderClass + ' ' + draggingOverClass}
                    id={idString}
                    icon='folder'
                    text={customChartFolder.name}
                    onClick={() => {
                        dispatch(setSelectedFolderId(customChartFolder.id))
                    }}
                    disabled={isDragging}>
                </Button>
                {/* Context Menu */}
                <ContextMenu
                    dataSource={contextMenuItems}
                    target={`#${idString}`}
                    onItemClick={(e:any) => e.itemData.action()}/>
            </div>
        </div>
    );
}

export default ChartFolderListItem;
