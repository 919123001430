import { Button } from 'devextreme-react';
import { DragLayerMonitor, useDragLayer } from 'react-dnd'
import { CustomChartDragTypes } from '../../../../../Classes/Charts/CustomChartDragTypes';
import { CustomChartFolder } from '../../../../../Classes/Charts/CustomChartFolder';
import { memo } from 'react';
import { CachedPreviewImage } from '../../../../../Classes/Charts/CachedPreviewImage';
import ColumnBox from '../../GraphSettings/ColumnBox/ColumnBox';
import { GraphInfo } from '../../../../../Classes/Charts/GraphInfo';

const CustomGraphDragLayer = (props: { cachedPreviewImageLookup: {[graphId: number]: CachedPreviewImage} }) => { 
    const cachedPreviewImageLookup = props.cachedPreviewImageLookup;
    const {isDragging, currentOffset, item, type} = useDragLayer(
        (monitor: DragLayerMonitor) => {
            return {
                isDragging: monitor.isDragging(),
                currentOffset: monitor.getSourceClientOffset(),
                item: monitor.getItem() as any,
                type: monitor.getItemType()
            };
        }
    )

    // Return null if no drag is occurring
    if (!isDragging || !currentOffset)
        return null

    let draggingStyle = {
        // functional
        transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
        position: 'fixed',
        top: 0,
        left: 0,
        pointerEvents: 'none', 

        // design only
        margin: '0em',
        opacity: 0.4,
        zIndex: 5
    } as React.CSSProperties

    // Render chart drag
    if (type === CustomChartDragTypes.CHART) {
        let chart = item as GraphInfo    
        const chartClass = 'savedGraphTemplate'
        let elementIdString = `customChartID${chart.id}`
        let originalItemWidth = document.getElementById(elementIdString)?.clientWidth
        let sharedMessage = ''
        if (chart.isSharedWithMe && chart.isSharedByMe) {
            sharedMessage = 'shared'
        }
        else if (chart.isSharedWithMe) {
            sharedMessage = 'shared with me'
        }
        else if (chart.isSharedByMe) {
            sharedMessage = 'shared by me'
        }

        // Get the cached preview image if one exists
        let previewImageURL = undefined;
        if (chart.id in cachedPreviewImageLookup)
            previewImageURL = cachedPreviewImageLookup[chart.id].imageURL;

        return (
            <div style={draggingStyle}>
                <button
                    className={chartClass}
                    disabled={true}
                    style={{width: originalItemWidth}}>
                    <div className='savedGraphTemplateContent'>
                        <h5>{chart.title}</h5>
                        <div style={{width:'100%', height:'7rem'}}>
                            {previewImageURL &&
                            <img 
                                src={previewImageURL} 
                                width={'100%'}
                                height={'100%'}
                                onError={(args) => (args.target as any).hidden = true} 
                                loading={'lazy'}
                                draggable={false}
                                alt=''/>}
                        </div>
                        {sharedMessage &&
                        <div className='sharedIndicator'>
                            <span>{sharedMessage}</span>
                            <i className='dx-icon-share'/>
                        </div>}
                    </div>
                </button>
            </div>
        )
    }
    // Render folder drag
    else if (type === CustomChartDragTypes.FOLDER) {
        let folder = item as CustomChartFolder
        const folderClass = 'graphFolderTemplate'
        let elementIdString = `customChartFolderID${folder.id}`
        let originalItemWidth = document.getElementById(elementIdString)?.clientWidth
        return (
            <div style={draggingStyle}>
                <Button 
                    className={folderClass}
                    style={{width: originalItemWidth}}
                    icon='folder'
                    text={folder.name}>
                </Button>
            </div>
        )
    }
    else if (type === CustomChartDragTypes.DATA_SOURCE_COLUMN) {
        return (
            <div style={draggingStyle}>
                <ColumnBox 
                    dataSourceColumn={item.dataSourceColumn}
                    numericColumnIds={item.numericColumnIds}/>
            </div>
        );
    }
    else {
        // This should not happen.
        return <div/>;
    }
};

export default memo(CustomGraphDragLayer);
  