import './GraphSettings.css';

import { useCallback, useMemo, useState } from 'react';
import useKeycloak from '../../../../Keycloak';
import GraphSetSelectorBox from '../../../Dashboard/SetManager/GraphSetManager/QuickGraph/GraphSetSelectorBox';
import { AddDbDataSourceColumn, RemoveDbColorByColumn, RemoveDbStackByColumn, RemoveDbYAxisColumn, SelectDbGraphSets, 
    SetDbBubbleSizeColumn, 
    SetDbColorByColumn, SetDbStackByColumn, SetDbXAxisColumn, SetDbYAxisColumn, SetDbYAxisGraphType } from '../../../../API/CustomChartAPI';
import { APIRequestStatus } from '../../../../Classes/APIRequestStatus';
import GraphSettingsAddButton from './GraphSettingsAddButton/GraphSettingsAddButton';
import { DataSourceColumn } from '../../../../Classes/DataSourceColumn';
import OptionSelectorPopup from './OptionSelector/OptionSelectorPopup';
import { AggregationType } from '../../../../Classes/Enums/AggregationType';
import ColumnBox from './ColumnBox/ColumnBox';
import { Button, List, Popover, Popup } from 'devextreme-react';
import DataTable from './DataTable/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../Stores/GlobalStore';
import { FetchZoneResultsForColumnProps, FetchZoneResultsProps, fetchFilterPropertiesAndOverrideCache, fetchZoneResultsAndOverrideCache, 
    fetchZoneResultsForColumn } from '../../../../Reducers/ResultsReducer';
import ColumnBoxDroppable from './ColumnBox/ColumnBoxDroppable';
import { YAxis } from '../../../../Classes/Charts/YAxis';
import { ChartType } from '../../../../Classes/Charts/ChartSettingsTypes';
import { getColumnDisplayName } from '../../../../Classes/ResultPropertyInfo';
import { ChartTypeObject, chartTypes } from '../../CommonChartTypes';
import { CustomChartSettings } from '../../../../Classes/Charts/CustomChartSettings';
import DataSourceSettingsButton from './GraphAdvancedSettingsButton/AdvancedSettingsButtons/DataSourceSettingsButton';
import XAxisSettingsButton from './GraphAdvancedSettingsButton/AdvancedSettingsButtons/XAxisSettingsButton';
import YAxisSettingsButton from './GraphAdvancedSettingsButton/AdvancedSettingsButtons/YAxisSettingsButton';
import GraphTypeSettingsButton from './GraphAdvancedSettingsButton/AdvancedSettingsButtons/GraphTypeSettingsButton';
import ColorBySettingsButton from './GraphAdvancedSettingsButton/AdvancedSettingsButtons/ColorBySettingsButton';
import { GraphDataSource } from '../../../../Classes/Charts/GraphDataSource';

type GraphSettingsProps = {
    graphSettings:CustomChartSettings;
    setGraphSettings: (newVal:CustomChartSettings) => void;
    tableDataSource:any[];
    graphDataSource:GraphDataSource|null;
    readOnly:boolean;
}

export function GraphSettings({ graphSettings, setGraphSettings, tableDataSource, graphDataSource, readOnly }: GraphSettingsProps) {

    const dispatch = useDispatch();
    const { token } = useKeycloak();
    
    const graphId = graphSettings.chartId;
    const {
        graphSetIds, dataSourceColumns, xAxisColumnId, dataSourceCategoryIds, stackColumnIds, yAxes, 
        colorByColumnIds, bubbleSizeColumnId
    } = graphSettings;

    const propertyKeyDict = useSelector((state:RootState) => state.results.plottablePropertyDictionary);

    const xAxisColumn = useMemo(() => {
        return dataSourceColumns.find(i => i.id === xAxisColumnId) ?? null
    }, [dataSourceColumns, xAxisColumnId]);

    const bubbleSizeColumn = useMemo(() => {
        return dataSourceColumns.find(i => i.id === bubbleSizeColumnId) ?? null
    }, [bubbleSizeColumnId, dataSourceColumns]);

    const [showDataTablePopup, setShowDataTablePopup] = useState<boolean>(false);
    const [showColumnSelectorPopup, setShowColumnSelectorPopup] = useState<boolean>(false);

    const [graphTypePopoverId, setGraphTypePopoverId] = useState<string|undefined>();

    const showBubbleSize = graphSettings.yAxes.find(i => i.chartType === ChartType.Bubble) != null;
    const hasDataSource = graphSetIds.length > 0;
    const showGraphTypeSection = hasDataSource && yAxes.length > 0;
    const showColorBySection = colorByColumnIds.length > 0 || (xAxisColumn != null && showGraphTypeSection && dataSourceColumns.length > 2 && !readOnly)
    const showStackBy = (yAxes.find(i => i.chartType === ChartType.StackedBar) != null) && (!readOnly || stackColumnIds.length > 0);

    const rowCount = tableDataSource.length.toLocaleString();
    const colCount = dataSourceColumns.length.toLocaleString();

    const deleteDataSourceColumn = useCallback((column:DataSourceColumn) => {
        // We don't need to update the database here, since ColumnBox handles that for us.

        // Update the data source columns, then update any dependant UI
        const newGraphSettings = {...graphSettings};
        newGraphSettings.dataSourceColumns = dataSourceColumns.filter(i => i.id !== column.id);

        // Update the data source category ids
        newGraphSettings.dataSourceCategoryIds = dataSourceCategoryIds.filter(id => id !== column.id);

        // Update the coloring modes
        newGraphSettings.colorByColumnIds = colorByColumnIds.filter(id => id !== column.id);

        // Update the y axes
        newGraphSettings.yAxes = yAxes.filter(yAxis => yAxis.dataSourceColumnId !== column.id);

        // Update the stacking columns
        newGraphSettings.stackColumnIds = stackColumnIds.filter(colId => colId !== column.id);

        // Remove the x axis if needed
        if (xAxisColumnId === column.id)
            newGraphSettings.xAxisColumnId = null;

        // Remove the bubble size if needed
        if (bubbleSizeColumnId === column.id)
            newGraphSettings.bubbleSizeColumnId = null;

        setGraphSettings(newGraphSettings);
    }, [bubbleSizeColumnId, colorByColumnIds, dataSourceCategoryIds, dataSourceColumns, graphSettings, setGraphSettings, stackColumnIds, xAxisColumnId, yAxes]);

    const onShowColumnSelectorPopup = useCallback(() => {
        setShowColumnSelectorPopup(true);
    }, [setShowColumnSelectorPopup]);

    return (
        <div className='graphSettingsWrapper'>
            <div className='graphSettings'>
                {readOnly &&
                <div className='readOnlyMessage'>
                    This graph is read-only. You cannot change its settings.
                </div>}
                <GraphSettingsSection 
                    name={'Data Source'} 
                    visible={!readOnly} 
                    headerContent={
                        <DataSourceSettingsButton 
                            visible={hasDataSource && showGraphTypeSection}
                            graphSettings={graphSettings}
                            setGraphSettings={setGraphSettings}/>
                    }>
                    <div>
                        <GraphSetSelectorBox 
                            selectedGraphSetIds={graphSetIds} 
                            onSelectionChange={(graphSetIds: number[]) => {
                                // Make this change in the UI
                                const oldVal = [...graphSetIds];
                                const newVal = graphSetIds;
                                setGraphSettings({...graphSettings, graphSetIds: newVal});

                                // Save new selection to the database
                                SelectDbGraphSets(token, graphId, graphSetIds).then(response => {
                                    if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                        // Revert this change if the DB step fails
                                        setGraphSettings({...graphSettings, graphSetIds: oldVal});
                                    }
                                    else {
                                        // If the save to the db succeeds, reload the cached results
                                        dispatch(fetchZoneResultsAndOverrideCache({
                                            authToken: token,
                                            graphId: graphId
                                        } as FetchZoneResultsProps));

                                        // Reload the filtered property keys too
                                        dispatch(fetchFilterPropertiesAndOverrideCache({
                                            authToken: token,
                                            graphId: graphId
                                        } as FetchZoneResultsProps));
                                    }
                                });
                            }}
                            visible={!readOnly}/>
                    </div>
                </GraphSettingsSection>
                <GraphSettingsSection
                    name='Data Source Columns'
                    visible={hasDataSource && !readOnly}
                    headerContent={
                        <Button
                            icon='tableproperties'
                            type='default'
                            onClick={(() => {
                                setShowDataTablePopup(true);
                            })}/>
                    }>
                    <div className='dataSourceColumns'>
                        {getEmptySectionMessageUI('No columns selected', dataSourceColumns.length === 0)}
                        {dataSourceColumns.map((column, index) => (
                            <div key={column.id + "_" + index}>
                                <ColumnBox 
                                    dataSourceColumn={column}
                                    deleteColumn={() => {
                                        deleteDataSourceColumn(column);
                                    }}
                                    numericColumnIds={graphDataSource?.numericColumnIds}/>
                            </div>
                        ))}
                        <GraphSettingsAddButton
                            onClick={onShowColumnSelectorPopup}/>
                    </div>
                </GraphSettingsSection>
                <GraphSettingsSection
                    name='X Axis'
                    visible={hasDataSource && (!readOnly || xAxisColumn != null)}
                    headerContent={
                        <XAxisSettingsButton
                            visible={xAxisColumn != null && !readOnly}
                            numericColSet={graphDataSource?.numericColumnIds}
                            graphSettings={graphSettings}
                            setGraphSettings={setGraphSettings}/>
                    }>
                    <div className='dataSourceColumns'>
                        <ColumnBoxDroppable 
                            readOnly={readOnly}
                            dataSourceColumn={xAxisColumn}
                            setDataSourceColumn={(column:DataSourceColumn) => {
                                const oldVal = xAxisColumnId;
                                const newVal = column.id;

                                // Make the change in the UI
                                setGraphSettings({...graphSettings, xAxisColumnId: newVal});

                                // Make the change in the DB
                                SetDbXAxisColumn(token, column.id).then(response => {
                                    if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                        // Revert the UI change if something goes wrong
                                        setGraphSettings({...graphSettings, xAxisColumnId: oldVal});
                                    }
                                });
                            }}
                            numericColumnIds={graphDataSource?.numericColumnIds}/>
                    </div>
                </GraphSettingsSection>
                <GraphSettingsSection
                    name='Y Axis'
                    visible={hasDataSource && (!readOnly || yAxes.length > 0)}
                    headerContent={
                        <YAxisSettingsButton
                            visible={yAxes.length > 0 && !readOnly}
                            numericColSet={graphDataSource?.numericColumnIds}
                            graphSettings={graphSettings}
                            setGraphSettings={setGraphSettings}/>
                    }>
                    <div className='dataSourceColumns'>
                        {yAxes.map((yAxis, index) => {
                            const yAxisColumn = dataSourceColumns.find(i => i.id === yAxis.dataSourceColumnId) ?? null;
                            const nullable = yAxes.length > 1;
                            return (
                                <ColumnBoxDroppable 
                                    key={yAxis.dataSourceColumnId + '_' + index}
                                    readOnly={readOnly}
                                    dataSourceColumn={yAxisColumn} 
                                    setDataSourceColumn={(newColumn:DataSourceColumn) => {
                                        const columnIdToReplace = yAxis.dataSourceColumnId;

                                        // Make the change in the UI
                                        const oldVal = [...yAxes];
                                        const newVal = [...oldVal.map(yAxis => {
                                            if (yAxis.dataSourceColumnId === columnIdToReplace)
                                                return {
                                                    ...yAxis,
                                                    dataSourceColumnId: newColumn.id
                                                };
                                            return yAxis;
                                        })]
                                        setGraphSettings({...graphSettings, yAxes: newVal});
        
                                        // Make the change in the DB.
                                        SetDbYAxisColumn(token, newColumn.id, columnIdToReplace).then(response => {
                                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                                // Revert the UI change if something goes wrong
                                                setGraphSettings({...graphSettings, yAxes: oldVal});
                                            }
                                        });
                                    }}
                                    removeColumn={() => {
                                        // Make the change in the UI
                                        const oldVal = [...yAxes];
                                        const newVal = oldVal.filter(i => i.dataSourceColumnId !== yAxis.dataSourceColumnId);
                                        setGraphSettings({...graphSettings, yAxes: newVal});
        
                                        // Make the change in the DB
                                        RemoveDbYAxisColumn(token, yAxis.dataSourceColumnId).then(response => {
                                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                                // Revert the UI change if something goes wrong
                                                setGraphSettings({...graphSettings, yAxes: oldVal});
                                            }
                                        });
                                    }}
                                    nullable={nullable}
                                    numericColumnIds={graphDataSource?.numericColumnIds}/>
                            );
                        })}
                        {(yAxes.length === 0 || dataSourceColumns.length > yAxes.length + 1) && !readOnly &&
                        <ColumnBoxDroppable
                            dataSourceColumn={null} 
                            setDataSourceColumn={(column:DataSourceColumn) => {
                                const newYAxis:YAxis = {
                                    dataSourceColumnId: column.id,
                                    chartType: ChartType.Line
                                }

                                // Make the change in the UI
                                const oldVal = [...yAxes];
                                const newVal = [...oldVal, newYAxis];
                                setGraphSettings({...graphSettings, yAxes: newVal});

                                // Make the change in the DB. Don't replace any existing columns.
                                const columnIdToReplace = 0;
                                SetDbYAxisColumn(token, column.id, columnIdToReplace).then(response => {
                                    if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                        // Revert the UI change if something goes wrong
                                        setGraphSettings({...graphSettings, yAxes: oldVal});
                                    }
                                });
                            }}
                            numericColumnIds={graphDataSource?.numericColumnIds}/>}
                    </div>
                </GraphSettingsSection>
                <GraphSettingsSection
                    name={'Graph Type'}
                    visible={showGraphTypeSection}
                    headerContent={
                        <GraphTypeSettingsButton
                            visible={showGraphTypeSection && !readOnly}
                            numericColSet={graphDataSource?.numericColumnIds}
                            graphSettings={graphSettings}
                            setGraphSettings={setGraphSettings}/>
                    }>
                    <div className='dataSourceColumns'>
                        {yAxes.map((yAxis, index) => {
                            const yAxisColumn = dataSourceColumns.find(i => i.id === yAxis.dataSourceColumnId) ?? null;
                            const yAxisColumnName = yAxisColumn ? getColumnDisplayName(yAxisColumn, propertyKeyDict) : "Unknown";
                            const selectedChartType = chartTypes.find(i => i.type === yAxis.chartType);
                            const graphTypeButtonId = `graphTypeButton${yAxis.dataSourceColumnId}`;
                            return (
                                <div key={yAxis.dataSourceColumnId + "_" + index}>
                                    <div 
                                        className='graphTypeEntry'>
                                        <Button 
                                            id={graphTypeButtonId} 
                                            disabled={readOnly}
                                            onClick={() => setGraphTypePopoverId(graphTypeButtonId)}>
                                            <div className='graphTypeNameAndIcon'>
                                                {selectedChartType?.icon &&
                                                <img
                                                    src={selectedChartType.icon}
                                                    alt=''
                                                    width={'24rem'}
                                                    height={'24rem'}/>}
                                                <span>
                                                    {selectedChartType?.text}
                                                </span>
                                            </div>
                                        </Button>
                                        {yAxes.length > 1 &&
                                        <div className='graphTypeEntryInfo'>
                                            for Y Axis type "{yAxisColumnName}"
                                        </div>}
                                    </div>
                                    <Popover
                                        target={`#${graphTypeButtonId}`}
                                        visible={graphTypePopoverId === graphTypeButtonId}
                                        position="bottom"
                                        width={'16em'}
                                        onHiding={() => setGraphTypePopoverId(undefined)}
                                        wrapperAttr={{class: 'graphTypePopoverWrapper'}}
                                        shadingColor="rgba(0, 0, 0, 0.25)">
                                            <div className='graphTypePopoverDiv'>
                                                <List 
                                                    dataSource={chartTypes}
                                                    selectionMode='single'
                                                    selectedItems={selectedChartType != null ? [selectedChartType] : []}
                                                    itemRender={(item:ChartTypeObject) => (
                                                        <div className='graphTypeNameAndIcon'>
                                                            {item.icon &&
                                                            <img
                                                                src={item.icon}
                                                                alt=''
                                                                width={'24rem'}
                                                                height={'24rem'}/>}
                                                            <span>
                                                                {item.text}
                                                            </span>
                                                        </div>
                                                    )}
                                                    onSelectionChanged={e => {
                                                        if (e.addedItems.length > 0) {
                                                            const newGraphType = e.addedItems[0].type;

                                                            // Close the popover
                                                            setGraphTypePopoverId(undefined);
                                                            
                                                            // Make the change in the UI
                                                            const oldVal = [...yAxes];
                                                            const newVal = yAxes.map(i => {
                                                                if (i.dataSourceColumnId === yAxis.dataSourceColumnId) {
                                                                    i.chartType = newGraphType;
                                                                }
                                                                return i;
                                                            });
                                                            setGraphSettings({...graphSettings, yAxes: newVal});

                                                            // Make the change in the DB
                                                            SetDbYAxisGraphType(token, yAxis.dataSourceColumnId, newGraphType).then(response => {
                                                                if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                                                    // Revert the UI change if something goes wrong
                                                                    setGraphSettings({...graphSettings, yAxes: oldVal});
                                                                }
                                                            });
                                                        }
                                                    }}/>
                                            </div>
                                    </Popover>
                                </div>
                            );
                        })}
                    </div>
                </GraphSettingsSection>
                <GraphSettingsSection
                    name='Bubble Size'
                    visible={showBubbleSize}>
                    <div className='dataSourceColumns'>
                        <ColumnBoxDroppable 
                            readOnly={readOnly}
                            dataSourceColumn={bubbleSizeColumn}
                            setDataSourceColumn={(column:DataSourceColumn) => {
                                const oldVal = bubbleSizeColumnId;
                                const newVal = column.id;

                                // Make the change in the UI
                                setGraphSettings({...graphSettings, bubbleSizeColumnId: newVal});

                                // Make the change in the DB
                                SetDbBubbleSizeColumn(token, column.id).then(response => {
                                    if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                        // Revert the UI change if something goes wrong
                                        setGraphSettings({...graphSettings, bubbleSizeColumnId: oldVal});
                                    }
                                });
                            }}
                            numericColumnIds={graphDataSource?.numericColumnIds}/>
                    </div>
                </GraphSettingsSection>
                <GraphSettingsSection
                    name='Color By'
                    visible={showColorBySection}
                    headerContent={
                        <ColorBySettingsButton
                            visible={graphDataSource != null && !readOnly}
                            graphSettings={graphSettings}
                            setGraphSettings={setGraphSettings}
                            graphDataSource={graphDataSource}/>
                    }>
                    <div className='dataSourceColumns'>
                        {colorByColumnIds.map((columnId, index) => {
                            const column = dataSourceColumns.find(i => i.id === columnId) ?? null;
                            return (
                                <ColumnBoxDroppable 
                                    key={columnId + "_" + index}
                                    readOnly={readOnly}
                                    dataSourceColumn={column} 
                                    setDataSourceColumn={(newColumn:DataSourceColumn) => {
                                        const columnIdToReplace = columnId;

                                        // Make the change in the UI
                                        const oldList = [...colorByColumnIds];
                                        const newValue = oldList.map(currId => {
                                            if (currId === columnIdToReplace)
                                                return newColumn.id;
                                            return currId;
                                        });
                                        setGraphSettings({...graphSettings, colorByColumnIds: newValue});
        
                                        // Make the change in the DB.
                                        SetDbColorByColumn(token, newColumn.id, columnIdToReplace).then(response => {
                                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                                // Revert the UI change if something goes wrong
                                                setGraphSettings({...graphSettings, colorByColumnIds: oldList});
                                            }
                                        });
                                    }}
                                    removeColumn={() => {
                                        // Make the change in the UI
                                        const oldList = [...colorByColumnIds];
                                        const newList = oldList.filter(i => i !== columnId);
                                        setGraphSettings({...graphSettings, colorByColumnIds: newList});
        
                                        // Make the change in the DB
                                        RemoveDbColorByColumn(token, columnId).then(response => {
                                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                                // Revert the UI change if something goes wrong
                                                setGraphSettings({...graphSettings, colorByColumnIds: oldList});
                                            }
                                        });
                                    }}
                                    nullable={true}
                                    numericColumnIds={graphDataSource?.numericColumnIds}/>
                            );
                        })}
                        {!readOnly &&
                        <ColumnBoxDroppable 
                            dataSourceColumn={null} 
                            setDataSourceColumn={(column:DataSourceColumn) => {
                                // Make the change in the UI
                                const oldList = [...colorByColumnIds];
                                const newList = [...colorByColumnIds, column.id];
                                setGraphSettings({...graphSettings, colorByColumnIds: newList});

                                // Make the change in the DB. Don't replace any existing columns.
                                const columnIdToReplace = 0;
                                SetDbColorByColumn(token, column.id, columnIdToReplace).then(response => {
                                    if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                        // Revert the UI change if something goes wrong
                                        setGraphSettings({...graphSettings, colorByColumnIds: oldList});
                                    }
                                });
                            }}
                            numericColumnIds={graphDataSource?.numericColumnIds}/>}
                    </div>
                </GraphSettingsSection>
                <GraphSettingsSection
                    name={'Stack Bars By'}
                    visible={showStackBy}>
                    <div className='dataSourceColumns'>
                        {stackColumnIds.map((columnId, index) => {
                            const column = dataSourceColumns.find(i => i.id === columnId) ?? null;
                            return (
                                <ColumnBoxDroppable 
                                    key={columnId + "_" + index}
                                    readOnly={readOnly}
                                    dataSourceColumn={column} 
                                    setDataSourceColumn={(newColumn:DataSourceColumn) => {
                                        const columnIdToReplace = columnId;

                                        // Make the change in the UI
                                        const oldList = [...stackColumnIds];
                                        const newValue = oldList.map(currId => {
                                            if (currId === columnIdToReplace)
                                                return newColumn.id;
                                            return currId;
                                        });
                                        setGraphSettings({...graphSettings, stackColumnIds: newValue});
        
                                        // Make the change in the DB.
                                        SetDbStackByColumn(token, newColumn.id, columnIdToReplace).then(response => {
                                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                                // Revert the UI change if something goes wrong
                                                setGraphSettings({...graphSettings, stackColumnIds: oldList});
                                            }
                                        });
                                    }}
                                    removeColumn={() => {
                                        // Make the change in the UI
                                        const oldList = [...stackColumnIds];
                                        const newList = oldList.filter(i => i !== columnId);
                                        setGraphSettings({...graphSettings, stackColumnIds: newList});
        
                                        // Make the change in the DB
                                        RemoveDbStackByColumn(token, columnId).then(response => {
                                            if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                                // Revert the UI change if something goes wrong
                                                setGraphSettings({...graphSettings, stackColumnIds: oldList});
                                            }
                                        });
                                    }}
                                    nullable={true}
                                    numericColumnIds={graphDataSource?.numericColumnIds}/>
                            );
                        })}
                        {!readOnly &&
                        <ColumnBoxDroppable 
                            dataSourceColumn={null} 
                            setDataSourceColumn={(column:DataSourceColumn) => {
                                // Make the change in the UI
                                const oldList = [...stackColumnIds];
                                const newList = [...stackColumnIds, column.id];
                                setGraphSettings({...graphSettings, stackColumnIds: newList});

                                // Make the change in the DB. Don't replace any existing columns.
                                const columnIdToReplace = 0;
                                SetDbStackByColumn(token, column.id, columnIdToReplace).then(response => {
                                    if (!APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                        // Revert the UI change if something goes wrong
                                        setGraphSettings({...graphSettings, stackColumnIds: oldList});
                                    }
                                });
                            }}
                            numericColumnIds={graphDataSource?.numericColumnIds}/>}
                    </div>
                </GraphSettingsSection>
            </div>
            <Popup
                title={`Source Data Table (${colCount} columns, ${rowCount} rows)`}
                wrapperAttr={{class: 'sourceDataTablePopupWrapper'}}
                visible={showDataTablePopup}
                hideOnOutsideClick={true}
                onHiding={() => setShowDataTablePopup(false)}>
                <DataTable
                    dataSourceTable={tableDataSource}
                    graphSettings={graphSettings}
                    setGraphSettings={setGraphSettings}
                    propertyKeyDictionary={propertyKeyDict}
                    showColumnSelectorPopup={onShowColumnSelectorPopup}
                    deleteUIColumn={deleteDataSourceColumn}/>
            </Popup>
            <OptionSelectorPopup
                currentKey={undefined}
                selectedAggType={AggregationType.First}
                propertyKeyLookup={propertyKeyDict}
                numericOnly={false}
                onApply={(newKey: string | undefined, newAggType: AggregationType) => {
                    if (newKey) {
                        // Make the change in the UI
                        const newColumn = new DataSourceColumn(0, newKey, newAggType);
                        const oldVal = [...dataSourceColumns];
                        const newVal = [...dataSourceColumns, newColumn];
                        setGraphSettings({...graphSettings, dataSourceColumns: newVal});

                        // Make the change in the db
                        AddDbDataSourceColumn(token, graphId, newKey, newAggType).then(response => {
                            if (APIRequestStatus.ensureNoErrorAndToastIfNotSuccess(response)) {
                                const columnId = response.data;
                                if (columnId != null) {
                                    newColumn.id = columnId;

                                    // Fetch results data for this new column
                                    dispatch(fetchZoneResultsForColumn({
                                        authToken: token,
                                        graphId: graphId,
                                        dataSourceColumnId: columnId
                                    } as FetchZoneResultsForColumnProps));
                                }
                            }
                            else {
                                // Revert this change if the DB step fails
                                setGraphSettings({...graphSettings, dataSourceColumns: oldVal});
                            }
                        });
                    }
                }} 
                show={showColumnSelectorPopup} 
                onHiding={() => setShowColumnSelectorPopup(false)}
                graphId={graphId}/>
        </div>
    );
}
export default GraphSettings;

export const getEmptySectionMessageUI = (message:string, showMessage:boolean) => {
    if (!showMessage) {
        return null;
    }

    return (
        <div className='emptySectionMessage'>
            {message}
        </div>
    );
}

interface GraphSettingsSectionProps {
    name:string, 
    visible:boolean,
    headerContent?:any,
    children:any
}

const GraphSettingsSection = ({name, visible, headerContent, children} : GraphSettingsSectionProps) => {
    if (!visible) {
        return null;
    }
    return (
        <div className='graphSettingsSection'>
            <div className='graphSettingsSectionLabel'>
                {name}
                {headerContent}
            </div>
            <div className='graphSettingsSectionContent'>
                {children}
            </div>
        </div>
    );
}